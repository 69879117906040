<template>
  <div class="policy-card margin-b-6">
    <v-layout justify-start align-center>
      <div>
        <CRIcon
          class="cursor-default"
          viewBox="0 0 24 24"
          width="40"
          height="40"
        >
          free_cancellation
        </CRIcon>
      </div>
      <div class="margin-l-2 margin-y-2">
        <span class="d-flex align-center">
          <h2 style="font-size: 16px; border: none;">Cancellation Policy</h2>
        </span>
        <p class="margin-t-0 font-14">
          {{ cancellationPolicyCopy }}
        </p>
        <InfoModal :modal-detail="modalDetail">
          <template #activator="{ on, attrs }">
            <div
              class="learn-more-text cursor-pointer margin-b-n4"
              v-bind="attrs"
              v-on="on"
            >
              Learn more
            </div>
          </template>
        </InfoModal>
      </div>
    </v-layout>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import InfoModal from './InfoModal.vue'
import { CancellationRefundPercentage } from '@/utils/enum'
import { refundPolicyModalDetail } from '@/utils/infoModal'

export default {
  components: {
    InfoModal,
  },
  props: {
    startDate: {
      type: String,
      default: '',
    },
    refundPolicyPercent: {
      type: Number,
      default: null,
    },
    refundPolicyPercentValidUntilTime: {
      type: String,
      default: null,
    },
    refundPolicy: {
      type: Array,
      default: []
    },
  },
  computed: {
    cancellationPolicyCopy() {
      if (this.refundPolicyPercent === CancellationRefundPercentage.Full) {
        // Full refund if first trip is 30+ days out
        return `Full refund before ${this.formattedCancellationPolicyDate}.`
      }
      if (this.refundPolicyPercent === CancellationRefundPercentage.None) {
        // No refund if first trip is within 3 days
        return 'This trip is non-refundable.'
      }
      // Partial refund if first trip is 29 - 3 days out
      return `This trip is ${this.refundPolicyPercent * 100}% refundable before ${this.formattedCancellationPolicyDate}.`
    },
    formattedCancellationPolicyDate() {
      if (!this.refundPolicyPercentValidUntilTime) {
        return null
      }
      return DateTime.fromISO(this.refundPolicyPercentValidUntilTime).toLocaleString({
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      })
    },
    modalDetail() {
      return refundPolicyModalDetail(this.refundPolicy)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/charterup/design';

.policy-card {
  border: 1px solid $border-gray;
  border-radius: 16px;
  padding: 10px 10px 10px 20px;
}

.learn-more-text {
  color: $blue !important;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 3px;
}
</style>
