
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'
import { marketplaceCheckoutDetailByQuoteIdAndProviderId } from '@/services/checkoutDetail'
import { convertQuote } from '@/services/charterup/quotes'
import { baseUrl } from '@/utils/env'
import * as logger from '@/utils/logger'
import { getCardNumberMask } from '@/utils/creditCard'
import { filter } from '@/utils/filter'
import { bookingProtectionModalDetail } from '@/utils/infoModal'
import {
  ChargeTypeId,
  CompanyId,
  CreditCardType,
  PaymentMethodTypeId,
  PaymentMethodTypeKey,
  SplitFeatureFlag,
} from '@/utils/enum'

import TwoColumnLayout from '@/layouts/TwoColumnLayout.vue'
import TripCard from '@/components/charterup/TripCard.vue'
import BillingSummary from '@/components/charterup/BillingSummary.vue'
import CreditCardSelector from '@/components/CreditCardSelector.vue'
import PaymentAndRefundPolicies from '@/components/charterup/PaymentAndRefundPolicies.vue'
import CheckoutAmenitiesCard from '@/components/charterup/CheckoutAmenitiesCard.vue'
import Confirm from '@/components/Confirm.vue'
import InfoModal from '@/components/charterup/InfoModal.vue'

import {
  AmenityType,
  CheckoutDetailResult,
  PaymentMethodType,
  Quote,
  RefundPolicyEntry,
  StagePaymentMethod,
  Trip,
  Vehicle,
} from '@/models/dto'
import { InfoModalDetail } from '@/models/InfoModalDetail'

@Component({
  components: {
    TripCard,
    BillingSummary,
    TwoColumnLayout,
    CreditCardSelector,
    InfoModal,
    PaymentAndRefundPolicies,
    CheckoutAmenitiesCard,
    Confirm,
  },
  directives: { mask }
})
export default class Checkout extends Vue {
  paymentMethodTypes: PaymentMethodType[] = []
  selectedPaymentMethodTypeId: number = null
  creditCard: any = null
  paymentProfiles: any[] = []
  address: any = {}
  usingStoredPaymentMethod: boolean = false
  selectorErrorMessages: {
    cardNumber: any,
    expirationDate: any,
  } = { cardNumber: null, expirationDate: null }
  checkoutDetail: CheckoutDetailResult = null
  isSubmitting: boolean = false
  providerId: number = null
  bidIds: number[] = null
  totalAmount: number = null
  isCharterUpChoice: boolean = false
  bidOrderIndex: number = null
  isNewerFleet: boolean = false
  hasTracking: boolean = false
  isPsychologicalPrice: boolean = false
  psychologicalAdjustmentPercent: number = 0
  psychologicalAdjustmentAmount: number = 0
  checkoutDetailLoading: boolean = false
  paymentServiceError: string = ''
  fullQuote: Quote = null
  showApproveDialog: boolean = false
  bookingProtectionModalDetail: InfoModalDetail = bookingProtectionModalDetail
  valueProps = [
    {
      icon: 'support_agent',
      title: '24/7 Support',
      description: 'Our award winning customer support is here for you.',
    },
    {
      icon: 'share_location',
      title: 'Live tracking',
      description: 'Real-time bus tracking from the first day of your trip.',
    },
    {
      icon: 'payments',
      title: 'Unbeatable prices',
      description: 'Largest marketplace for charter buses in the U.S.',
    },
  ]
  paymentMethodTypeIdToKey = {
    1: PaymentMethodTypeKey.CreditCard,
    2: PaymentMethodTypeKey.ACH,
    3: PaymentMethodTypeKey.Check,
  }

  get customerId(): number {
    return this.checkoutDetail?.quote?.trips?.[0]?.customerId
  }

  get quoteTrips(): Trip[] {
    return this.checkoutDetail?.quote?.trips
  }

  get quoteCreatedOn() {
    return this.checkoutDetail?.quote?.createdOn
  }

  get quote(): Quote {
    return this.checkoutDetail?.quote
  }

  get isPaymentMethodSelectionNeeded(): boolean {
    return (
      this.isPaymentMethodAllowed(PaymentMethodTypeId.CreditCard) &&
      (this.isPaymentMethodAllowed(PaymentMethodTypeId.ACH)
        || this.isPaymentMethodAllowed(PaymentMethodTypeId.Check))
    )
  }

  get baseFare(): number {
    return this.checkoutDetail?.charges?.find(
      (charge) => charge.chargeType.id === ChargeTypeId.BaseFare
    )?.amount
  }

  get discount(): number {
    return this.checkoutDetail?.charges?.find(
      (charge) => charge.chargeType.id === ChargeTypeId.Discount
    )?.amount
  }

  get processingFees(): number {
    return this.checkoutDetail?.charges?.find(
      (charge) => charge.chargeType.id === ChargeTypeId.ProcessingFee
    )?.amount
  }

  get amenitiesCharge(): number {
    return this.checkoutDetail?.charges?.find(
      (charge) => charge.chargeType.id === ChargeTypeId.Amenities
    )?.amount
  }

  get paymentMethodSelectOptions(): PaymentMethodTypeId[] {
    const options = []
    if (this.isPaymentMethodAllowed(PaymentMethodTypeId.CreditCard)) {
      options.push(PaymentMethodTypeId.CreditCard)
    }

    if (this.isPaymentMethodAllowed(PaymentMethodTypeId.Check)) {
      options.push(PaymentMethodTypeId.Check)
    } else if (this.isPaymentMethodAllowed(PaymentMethodTypeId.ACH)) {
      options.push(PaymentMethodTypeId.ACH)
    }
    return options
  }

  get checkoutPaymentMethods(): StagePaymentMethod[] {
    return this.checkoutDetail?.quote?.trips?.[0]?.stagePaymentMethods?.checkoutPaymentMethods || []
  }

  get activePaymentMethod(): PaymentMethodTypeKey {
    if (this.selectedPaymentMethodTypeId) {
      return this.paymentMethodTypeIdToKey[this.selectedPaymentMethodTypeId]
    }
    if (this.isPaymentMethodAllowed(PaymentMethodTypeId.CreditCard)) {
      return PaymentMethodTypeKey.CreditCard
    }
    if (this.isPaymentMethodAllowed(PaymentMethodTypeId.ACH)) {
      return PaymentMethodTypeKey.ACH
    }
    if (this.isPaymentMethodAllowed(PaymentMethodTypeId.Check)) {
      return PaymentMethodTypeKey.Check
    }
    return null
  }

  get showCreditCardInput(): boolean {
    return this.activePaymentMethod === PaymentMethodTypeKey.CreditCard
  }

  get showCheckACHInput(): boolean {
    return this.activePaymentMethod === PaymentMethodTypeKey.Check
      || this.activePaymentMethod === PaymentMethodTypeKey.ACH
  }

  get expirationMonth(): string {
    if (this.creditCard?.newCard?.expiration) {
      let expiration = this.creditCard?.newCard?.expiration.split('/')
      return expiration.length ? expiration[0] : null
    } else {
      return null
    }
  }

  get expirationYear(): string {
    if (this.creditCard?.newCard?.expiration) {
      let expiration = this.creditCard?.newCard?.expiration.split('/')
      return expiration.length ? expiration[1] : null
    } else {
      return null
    }
  }

  get cardType(): CreditCardType {
    let cardNumber = this.creditCard?.newCard?.cardNumber
    if (!cardNumber) {
      return CreditCardType.Default
    }

    if (cardNumber[0] === '5') {
      return CreditCardType.Mastercard
    } else if (cardNumber[0] === '4') {
      return CreditCardType.Visa
    } else if (getCardNumberMask[0] === '6') {
      return CreditCardType.Discover
    } else if (['34', '37'].includes(cardNumber?.substring(0, 2))) {
      return CreditCardType.AmericanExpress
    } else if (['30', '36', '38'].includes(cardNumber?.substring(0, 2))) {
      return CreditCardType.Diners
    } else {
      return CreditCardType.Default
    }
  }

  get cardMask(): string {
    if (!this.creditCard?.newCard?.cardNumber) return ''
    return this.creditCard.newCard.cardNumber.substr(
      this.creditCard.newCard.cardNumber.length - 4,
      4
    )
  }

  get refundPolicyPercent(): number {
    return this.checkoutDetail?.refundPolicyPercent
  }

  get refundPolicyValidUntilTime(): string {
    return this.checkoutDetail?.refundPolicyPercentValidUntilTime
  }

  get refundPolicy(): RefundPolicyEntry[] {
    return this.checkoutDetail?.refundPolicy
  }

  get firstTripStartDate(): string {
    if (this.checkoutDetail?.quote?.trips?.length > 0) {
      return this.checkoutDetail?.quote?.trips[0].startDate
    }
    return null
  }

  get isSelfServe(): boolean {
    return this.checkoutDetail?.quote?.isSelfServe
  }

  get amenities(): AmenityType[] {
    return this.checkoutDetail?.quote?.trips?.[0]?.tripAmenities
  }

  get vehicles(): Vehicle[] {
    return this.checkoutDetail?.quote?.trips?.[0]?.vehicles
  }

  get isQuoteCategoryPricingMethod(): boolean {
    return this.quote?.pricingMethod === 'category'
  }

  get showApproveQuote(): boolean {
    return this.quote?.trips.some((trip) => trip?.paymentType?.id === 3)
  }

  get currentUserProfile() {
    return this.$store.getters['auth/currentUserProfile'] || {}
  }

  get isBrokerAdmin(): boolean {
    return this.currentUserProfile?.isBrokerAdmin
  }

  get isRAManager(): boolean {
    return this.currentUserProfile?.isRAManager
  }

  async mounted(): Promise<void> {
    this.checkoutDetailLoading = true

    try {
      await this.loadCheckoutDetails()
      await this.loadCustomerPaymentProfiles()
      await this.loadFullQuote()

      const quoteTypesResponse = await this.$store.dispatch('types/getQuoteTypes')
      this.paymentMethodTypes = quoteTypesResponse?.data?.paymentMethods || []

      await this.$store.dispatch('split/updateKey', {
        key: this.customerId
      })

      this.checkoutDetailLoading = false
    } catch (err) {
      logger.error(err)
      this.checkoutDetailLoading = false
    }
  }

  setSelectedCard(card): void {
    if (card?.customerPaymentProfileId) {
      this.usingStoredPaymentMethod = true
    } else {
      this.usingStoredPaymentMethod = false
    }
  }

  async loadCheckoutDetails(): Promise<void> {
    const quoteId: number = parseInt(this.$route.params.quoteId)
    const providerId: number = parseInt(this.$route.params.providerId)
    const { data } = await marketplaceCheckoutDetailByQuoteIdAndProviderId(quoteId, providerId)

    this.checkoutDetail = data
    this.providerId = providerId
    this.bidIds = data.bidIds
    this.bidOrderIndex = data.bidOrderIndex
    this.isNewerFleet = data.isNewerFleet
    this.hasTracking = data.hasTracking
    this.totalAmount = data.totalAmount
    this.isCharterUpChoice = data.isCharterUPChoice
    this.isPsychologicalPrice = data.isPsychologicalPrice
    this.psychologicalAdjustmentPercent = data.psychologicalAdjustmentPercent
    this.psychologicalAdjustmentAmount = data.psychologicalAdjustmentAmount
  }

  async loadCustomerPaymentProfiles(): Promise<void> {
    const filters = filter()

    if (!this.customerId) {
      this.paymentProfiles = []
      return
    }

    const parentFilter = filters.createParent('and')
    const customerIdFilter = {
      column: {
        _t_id: 'customerIdFilter_filter',
        prop: 'customerId',
        filterType: 'eq',
      },
      value: this.customerId,
    }
    const showToCustomerFilter = {
      column: {
        _t_id: 'showToCustomerFilter_filter',
        prop: 'showToCustomer',
        filterType: 'eq',
      },
      value: true,
    }
    const showOnCharterUpFilter = {
      column: {
        _t_id: 'showOnCharterUpFilter_filter',
        prop: 'showOnCharterUp',
        filterType: 'eq',
      },
      value: true,
    }
    filters.add(parentFilter, customerIdFilter)
    filters.add(parentFilter, showToCustomerFilter)
    filters.add(parentFilter, showOnCharterUpFilter)
    const params = {
      pageSize: -1,
      filters: filters.asQueryParams(),
    }
    const customerPaymentProfilesResponse = await this.$store.dispatch(
      'payments/getCustomerPaymentProfiles', params
    )
    const paymentProfilesResults = customerPaymentProfilesResponse?.data?.resultList || []
    this.paymentProfiles = paymentProfilesResults.map((result) => {
      return {
        customerPaymentProfileId : result.customerPaymentProfileId,
        mask: result.mask,
        typeLabel: result.label,
        address: result.address,
        companyPaymentGateway: result.companyPaymentGateway,
        accountHolderName: result.accountHolderName,
        expiration: result.expiration,
      }
    })
  }

  async loadFullQuote(): Promise<void> {
    const quoteResult = await this.$store.dispatch(
      'quotes/getV2Quote',
      this.quote.quoteId
    )
    this.fullQuote = quoteResult.data.quote
  }

  clearErrorMessages(): void {
    this.selectorErrorMessages = {
      cardNumber: [],
      expirationDate: [],
    }
    this.paymentServiceError = ''
  }

  async submitCheckout(event): Promise<void> {
    event.preventDefault()

    if (this.isSubmitting) {
      return
    }
    this.clearErrorMessages()

    // Validate the form
    if (this.showCreditCardInput && !this.usingStoredPaymentMethod) {
      const newCardForm: any = this.$refs['credit-card-selector']
      const newCardFormIsValid = newCardForm.validate()
      if (!newCardFormIsValid) {
        return
      }

    } else if (this.showCreditCardInput && !this.usingStoredPaymentMethod && !this.creditCard?.newCard) {
      return
    } else if (this.showCheckACHInput) {
      const addressForm: any = this.$refs['check-address-selector']
      let checkFormIsValid = addressForm.validate()
      if (!checkFormIsValid) {
        return
      }
    }

    let tokenizedPaymentInfo
    let defaultPaymentGateway
    let paymentMethodData: any = { activeMethod: this.activePaymentMethod }
    let saveForFuturePayments = this.creditCard?.saveForFuturePayments || false
    this.isSubmitting = true

    // Build paymentMethodData
    if (this.showCreditCardInput && !this.usingStoredPaymentMethod) {
      paymentMethodData = {
        ...paymentMethodData,
        name: this.creditCard?.newCard?.name,
        cardholderName: this.creditCard?.newCard?.name,
        cardNumber: this.creditCard?.newCard?.cardNumber,
        mask: this.cardMask,
        securityCode: this.creditCard?.newCard?.securityCode,
        exp_date: this.creditCard?.newCard?.expiration,
        expirationMonth: this.expirationMonth,
        expirationYear: this.expirationYear,
        type_label: this.cardType,
        address: this.creditCard?.newCard?.address,
      }

      paymentMethodData.address = {
        ...paymentMethodData.address,
        time_zone: paymentMethodData.address.timeZone,
        name: paymentMethodData.address.addressName,
        postalCode: paymentMethodData.address.postalCode,
        title: paymentMethodData.address.title,
      }

      delete paymentMethodData.address.timeZone
      delete paymentMethodData.address.addressName

    } else if (this.showCreditCardInput && this.usingStoredPaymentMethod) {
      paymentMethodData = {
        ...paymentMethodData,
        name: this.creditCard?.accountHolderName,
        cardholderName: this.creditCard?.accountHolderName,
        address: this.creditCard?.address,
        exp_date: this.creditCard?.expiration,
        mask: this.creditCard?.mask,
        type_label: this.creditCard?.typeLabel,
      }

    } else {
      // Check or Wire Payment
      paymentMethodData = {
        ...paymentMethodData,
        address: this.address,
      }
    }

    // Get Tokenized Payment Info
    if (this.showCreditCardInput && !this.usingStoredPaymentMethod) {
      const quote = {
        ...this.checkoutDetail?.quote,
        companyId: CompanyId.CharterUP,
      }
      try {
        const cardForm: any = this.$refs['credit-card-selector']
        const tokenizeResponse = await cardForm.tokenizeCard()
        const { paymentGateway, tokens, formattedPaymentInfo } = tokenizeResponse

        tokenizedPaymentInfo = {
          tokens,
          paymentGateway,
        }

      } catch (error: any) {
        error.forEach((specificError) => {
          if (specificError.includes('credit card')) {
            this.selectorErrorMessages.cardNumber = specificError

          } else if (specificError.includes('expir') || specificError.includes('Expir')) {
            this.selectorErrorMessages.expirationDate = specificError

          } else if (specificError.includes('OTS')) {
            this.selectorErrorMessages.expirationDate = 'Card expiration is not valid'

          } else {
            this.paymentServiceError = specificError
          }
        })
        this.isSubmitting = false
        return
      }
    } else if (this.showCreditCardInput && this.usingStoredPaymentMethod) {
      const defaultPaymentGatewayResponse = await this.$store.dispatch(
        'payments/getDefaultPaymentGateway', CompanyId.CharterUP
      )
      defaultPaymentGateway = defaultPaymentGatewayResponse.data.paymentGateways[0]
      defaultPaymentGateway = {
        key: defaultPaymentGateway.paymentGatewayTypeKey,
        id: defaultPaymentGateway.companyPaymentGatewayId,
      }

    } else {
      // Don't tokenize payment info for check/wire payments
      tokenizedPaymentInfo = {
        tokens: [],
        paymentGateway: null,
      }
    }

    // Convert the quote
    try {
      let convertQuoteResponse
      if (this.usingStoredPaymentMethod) {
        convertQuoteResponse = await convertQuote(
          this.checkoutDetail?.quote,
          defaultPaymentGateway,
          paymentMethodData,
          this.bidIds,
          this.creditCard?.customerPaymentProfileId,
          true
        )
      } else {
        convertQuoteResponse = await convertQuote(
          this.checkoutDetail?.quote,
          tokenizedPaymentInfo,
          paymentMethodData,
          this.bidIds,
          null,
          saveForFuturePayments
        )
      }

      if (convertQuoteResponse?.data?.successful) {
        const quoteProperties = {
          isCheapestBidSelected: this.bidOrderIndex === 0,
          indexOfSelectedBid: this.bidOrderIndex,
          isNewerFleetBid: this.isNewerFleet,
          isHasTrackingBid: this.hasTracking,
          totalAmount: this.totalAmount,
          isCharterUpChoice: this.isCharterUpChoice,
          isPsychologicalPrice: this.isPsychologicalPrice,
          psychologicalAdjustmentAmount: this.psychologicalAdjustmentAmount,
          psychologicalAdjustmentPercent: this.psychologicalAdjustmentPercent
        }

        this.$store.dispatch('split/trackQuoteConverted', quoteProperties)

        this.$router.push({
          name: 'reservations',
        })

        this.$nextTick(() => {
          this.$store.dispatch('app/showAlert', {
            message: `Quote successfully converted`,
            type: 'success',
          })
        })
      }
    } catch (error: any) {
      if (
        error.response?.data?.message?.includes('expir') ||
        error.response?.data?.message?.includes('Expir')
      ) {
        this.selectorErrorMessages.cardNumber = [error.response.data.message]
      } else {
        this.paymentServiceError =
          error.response?.data?.message ||
          'Could not process this transaction.'
      }
      this.isSubmitting = false
    }
  }

  isPaymentMethodAllowed(paymentMethodTypeId: number): boolean {
    if (this.checkoutPaymentMethods.length === 0) {
      return false
    }
    const paymentMethod = this.checkoutPaymentMethods.find(
      (method) => method.paymentMethodId === paymentMethodTypeId
    )
    return paymentMethod.isAllowed === 1
  }

  async copyMarketplaceLink(): Promise<void> {
    try {
      const linkResponse = await this.$store.dispatch('quotes/getMarketplaceLink', this.quote?.quoteId)
      const link = linkResponse.data.link
      navigator.clipboard.writeText(link).then(
        () => {
          this.$store.dispatch('app/showAlert', {
            type: 'success',
            message: 'Marketplace link copied to your clipboard',
          })
        },
        () => {
          this.$store.dispatch('app/showAlert', {
            type: 'error',
            message: 'Marketplace link could not be copied to your clipboard',
          })
        }
      )
    } catch (e) {
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'There was an error getting the marketplace link',
      })
    }
  }

  async approveBASQuote(): Promise<void> {
    this.showApproveDialog = false
    const approveQuotePayload = {
      meta: { billing: {} },
      manual: true,
      payment_method: PaymentMethodTypeKey.Check,
    }
    const params = {
      quoteId: this.quote.quoteId,
      payload: approveQuotePayload,
    }
    const approveQuoteResponse = await this.$store.dispatch('quotes/convert', params)
    if (approveQuoteResponse.status !== 200) {
      const errorMessage = approveQuoteResponse?.data?.message
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: `Error Approving Quote: ${errorMessage}`,
      })
    } else {
      this.$router.push({ name: 'quotes' })
      this.$store.dispatch('app/showAlert', {
        message: 'Successfully approved quote',
      })
    }
  }

  pdfUrl(): string {
    const pdfHost = baseUrl('pdf')
    const { updatedOn, hash } = this.fullQuote
    const timestamp = new Date(updatedOn).getTime()
    return `https://${pdfHost}/pdf/quote-${hash}-${timestamp}.pdf`
  }

  viewPDF(): void {
    window.open(this.pdfUrl(), '_blank')
  }

  copyPDFUrl(): void {
    navigator.clipboard.writeText(this.pdfUrl())
    this.$store.dispatch('app/showAlert', {
      message: 'PDF url copied to clipboard.',
    })
  }

  updateLeadTempAndFollowUpDate(): void {
    const component = () =>
      import('@/components/QuoteFormCRMActionsSidebar.vue')
    this.$store.dispatch('app/openSidebarDialog', {
      data: {
        title: 'CRM Actions',
        quoteId: this.quote.quoteId,
      },
      component,
    })
  }

  updateSentBy(): void {
    const component = () => import('@/components/QuoteCreatedBySidebar.vue')
    this.$store.dispatch('app/openSidebarDialog', {
      data: {
        title: 'Update Sent By User',
        quoteId: this.quote.quoteId,
      },
      component,
    })
  }

  async handleEmailCampaignStatusChange(status: boolean, quoteId: number): Promise<void> {
    await this.$store.dispatch('quotes/changeEmailCampaignStatus', { status, quoteId })
    this.fullQuote.participatingInEmailCampaigns = status
  }

  getTabLabel(paymentMethodTypeId: number): string {
    if (paymentMethodTypeId === PaymentMethodTypeId.CreditCard) {
      return 'Credit Card'
    }
    if (paymentMethodTypeId === PaymentMethodTypeId.ACH
        || paymentMethodTypeId === PaymentMethodTypeId.Check) {
      return 'Check / Wire'
    }
  }
}

